import { AnalyticsUser } from '@remento/web-analytics-client/@types';

import { IntercomBootProps, IntercomProps, IntercomService } from '@/services/analytics/intercom/intercom.types';

let booted = false;

function getIntercomUser(user: AnalyticsUser): IntercomProps {
  if (user.type === 'anonymous') {
    return {};
  }

  return {
    user_id: user.id,
    name: user.traits.name ?? undefined,
    email: user.traits.email ?? undefined,
    session_duration: Number.MAX_SAFE_INTEGER,
  };
}

export class DefaultIntercomService implements IntercomService {
  constructor(
    private intercomAppId: string,
    user: AnalyticsUser,
  ) {
    this.loadIntercom();
    this.boot(getIntercomUser(user));
  }

  async identify(user: AnalyticsUser): Promise<void> {
    this.boot(getIntercomUser(user));
  }

  async reset(): Promise<void> {
    this.shutdown();
  }

  setChatDisplayed(display: boolean): void {
    if (display) {
      this.makeApiCall('show');
    } else {
      this.makeApiCall('hide');
    }
  }

  setBubbleDisplayed(display: boolean): void {
    this.update({ hide_default_launcher: !display });
  }

  private update(props?: IntercomProps) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.intercomSettings = { ...window.intercomSettings, ...props };
    this.makeApiCall('update', props);
  }

  private shutdown() {
    if (!booted) return;
    this.makeApiCall('shutdown');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete window.intercomSettings;
    booted = false;
  }

  private boot(props?: IntercomProps) {
    if (booted) {
      this.update(props);
      return;
    }
    const metaData: IntercomBootProps = {
      app_id: this.intercomAppId,
      ...props,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.intercomSettings = metaData;
    this.makeApiCall('boot', metaData);
    booted = true;
  }

  private makeApiCall(method: string, ...args: Array<unknown>) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.Intercom) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return window.Intercom.apply(null, [method, ...args]);
    }

    throw new Error('Intercom not initialized');
  }

  private loadIntercom() {
    const w = window;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const i = function (...args) {
        i.c(args);
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      i.q = [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      i.c = function (args) {
        i.q.push(args);
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      w.Intercom = i;

      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + this.intercomAppId;
      const x = d.getElementsByTagName('script')[0];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      x.parentNode.insertBefore(s, x);
    }
  }
}
