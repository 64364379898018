import { Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { RecordingIntroLoader } from '@/modules/recording/components/RecordingIntroLoader/RecordingIntroLoader';
import { LegacyRedirect } from '@/modules/router/components/LegacyRedirect.tsx';

import { AppRouterErrorBoundary } from './AppRouterErrorBoundary.js';
import { PageLoader } from './components/PageLoader/PageLoader.js';
import { OnboardingContainer } from './modules/onboarding/containers/Onboarding.container.js';
import { NavigateWithParams, NavigateWithParamsCallback } from './modules/router/components/NavigateWithParams.js';
import {
  getAccountSettingsPath,
  getCheckoutAudiencePath,
  getProjectPromptsPath,
  getProjectSettingsPath,
  getRecordingIntroPath,
  getRecordingPath,
  getRecordingPromptRecordedPath,
  getRecordingTypeSelectionPath,
  getStandaloneStoryViewPath,
  getStoryViewPath,
  RementoPage,
} from './modules/routing/index.js';
import { RootRoute } from './modules/routing/RootRoute.js';
import { RoutePath } from './modules/routing/types/routing.types.js';
import { RootPage } from './pages/RootPage/RootPage.js';
import { TokenRedirectPage } from './pages/TokenRedirectPage/TokenRedirectPage.js';
import {
  AccountNamePage,
  AccountReactivatePage,
  AccountRenewPromoPage,
  AccountSettingsPage,
  AutobiographyProjectCheckoutContactStepContainer,
  AutobiographyProjectCheckoutReviewStepContainer,
  BabybookProjectCheckoutBookStepContainer,
  BabybookProjectCheckoutContactStepContainer,
  BabybookProjectCheckoutReviewStepContainer,
  BiographyProjectCheckoutAddonsStepContainer,
  BiographyProjectCheckoutContactStepContainer,
  BiographyProjectCheckoutDeliveryStepContainer,
  BiographyProjectCheckoutReviewStepContainer,
  BookCover,
  BookGutsCover,
  BookOrderCompletePage,
  BookOrderCoverDesignPage,
  BookOrderFinalizePage,
  BookOrderLayoutContainer,
  BookOrderPreviewPage,
  BookOrderQuantityPage,
  BookOrderStoriesPage,
  BookReorderFinalizePage,
  BookReorderLayoutContainer,
  BookReorderPreviewPage,
  BookReorderQuantityPage,
  BookStoryContent,
  BookTableOfContents,
  CheckoutBundlePage,
  CheckoutGiftPage,
  DebugDeleteAccountPage,
  FreeProjectCheckoutDetailsStepContainer,
  FreeProjectCheckoutReviewStepContainer,
  GiftCard,
  InAppPage,
  InvitePage,
  NewProjectPage,
  OnboardingIntroPage,
  OnboardingSigninPage,
  PollPage,
  ProjectCheckoutAudiencePage,
  ProjectCheckoutContainer,
  ProjectCheckoutGetStartedPage,
  ProjectCheckoutPage,
  ProjectCheckoutSuccessPage,
  ProjectCheckoutSurveyPage,
  ProjectRecordCustomizePage,
  ProjectRecordPage,
  ProjectSettingsPage,
  QuestionnairePage,
  QuestionsPage,
  RecordingFinishPage,
  RecordingIntroPage,
  RecordingPageContainer,
  RecordingPaywallPage,
  RecordingPromptRecordedPage,
  RecordingRecoveryPage,
  RecordingTypeSelectionPageContainer,
  SetupCollaboratorsPage,
  SetupPerspectivePage,
  SetupPromptsFrequencyPage,
  SetupPromptsReviewPage,
  SetupPromptTypeSelectionPage,
  SetupTextPromptSelectionPage,
  SigninPage,
  StoriesPage,
  StoryHighlightReelPage,
  StoryPage,
  SubscriptionCancelledSurveyPage,
  SubscriptionRenewalSucceededPage,
  WelcomePage,
} from './routes.lazy.js';

// Elements are declared in ./routes.lazy.ts to allow fast refresh
const subRoutes: RouteObject[] = [
  {
    path: RoutePath.Root,
    index: true,
    element: <RootPage />,
  },
  {
    path: RoutePath.StoryStandalone,
    element: (
      <Suspense fallback={<PageLoader />}>
        <StoryPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.Story,
    element: (
      <Suspense fallback={<PageLoader layout="navbar" />}>
        <StoriesPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.StoryHighlightReel,
    element: (
      <Suspense fallback={<PageLoader layout="navbar" />}>
        <StoryHighlightReelPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.Stories,
    element: (
      <Suspense fallback={<PageLoader layout="navbar" />}>
        <StoriesPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.Questions,
    element: (
      <Suspense fallback={<PageLoader layout="navbar" />}>
        <QuestionsPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.ProjectSettings,
    element: (
      <Suspense fallback={<PageLoader layout="navbar" />}>
        <ProjectSettingsPage />
      </Suspense>
    ),
  },
  // Account and subscription
  {
    path: RoutePath.AccountSettings,
    element: (
      <Suspense fallback={<PageLoader layout="navbar" />}>
        <AccountSettingsPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.AccountRenewPromo,
    element: (
      <Suspense fallback={<PageLoader />}>
        <AccountRenewPromoPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.AccountReactivate,
    element: (
      <Suspense fallback={<PageLoader />}>
        <AccountReactivatePage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.SubscriptionRenewalSucceeded,
    element: (
      <Suspense fallback={<PageLoader />}>
        <SubscriptionRenewalSucceededPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.SubscriptionCancelledSurvey,
    element: (
      <Suspense fallback={<PageLoader />}>
        <SubscriptionCancelledSurveyPage />
      </Suspense>
    ),
  },
  // Redirects
  {
    path: RoutePath.StoriesLegacyRedirect,
    element: <RementoPage type="default" />,
  },
  {
    path: RoutePath.QuestionsLegacyRedirect,
    element: <RementoPage type="default" />,
  },
  {
    path: RoutePath.ProjectSettingsLegacyRedirect,
    element: <RementoPage type="default" />,
  },
  {
    path: RoutePath.ProjectStoriesRedirect,
    element: <RementoPage type="default" />,
  },
  {
    path: RoutePath.ProjectQuestionsRedirect,
    element: <RementoPage type="default" />,
  },
  {
    path: RoutePath.ProjectSettingsRedirect,
    element: <RementoPage type="default" />,
  },
  // Recording
  {
    path: RoutePath.RecordingIntro,
    element: (
      <Suspense fallback={<RecordingIntroLoader />}>
        <RecordingIntroPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.RecordingTypeSelection,
    element: (
      <Suspense fallback={<PageLoader />}>
        <RecordingTypeSelectionPageContainer />
      </Suspense>
    ),
  },
  {
    path: RoutePath.Recording,
    element: (
      <Suspense fallback={<PageLoader />}>
        <RecordingPageContainer />
      </Suspense>
    ),
  },
  {
    path: RoutePath.RecordingPromptRecorded,
    element: (
      <Suspense fallback={<PageLoader />}>
        <RecordingPromptRecordedPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.RecordingPaywall,
    element: (
      <Suspense fallback={<PageLoader />}>
        <RecordingPaywallPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.RecordingRecovery,
    element: (
      <Suspense fallback={<PageLoader />}>
        <RecordingRecoveryPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.RecordingFinish,
    element: (
      <Suspense fallback={<PageLoader />}>
        <RecordingFinishPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.ProjectRecord,
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProjectRecordPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.ProjectRecordCustomize,
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProjectRecordCustomizePage />
      </Suspense>
    ),
  },
  // Checkout
  {
    path: RoutePath.Checkout,
    element: <Navigate to={getCheckoutAudiencePath()} />,
    // element: (
    //   <Suspense fallback={<PageLoader />}>
    //     <ProjectCheckoutPage />
    //   </Suspense>
    // ),
  },
  {
    path: RoutePath.CheckoutBiographyAudience,
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProjectCheckoutAudiencePage projectType="BIOGRAPHY" />
      </Suspense>
    ),
  },
  {
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProjectCheckoutContainer projectType="BIOGRAPHY" />
      </Suspense>
    ),
    children: [
      {
        path: RoutePath.CheckoutBiographyDelivery,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BiographyProjectCheckoutDeliveryStepContainer />
          </Suspense>
        ),
      },
      {
        path: RoutePath.CheckoutBiographyContact,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BiographyProjectCheckoutContactStepContainer />
          </Suspense>
        ),
      },
      {
        path: RoutePath.CheckoutBiographyAddons,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BiographyProjectCheckoutAddonsStepContainer />
          </Suspense>
        ),
      },
      {
        path: RoutePath.CheckoutBiographyReview,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BiographyProjectCheckoutReviewStepContainer />
          </Suspense>
        ),
      },
    ],
  },
  {
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProjectCheckoutContainer projectType="AUTOBIOGRAPHY" />
      </Suspense>
    ),
    children: [
      {
        path: RoutePath.CheckoutAutobiographyContact,
        element: (
          <Suspense fallback={<PageLoader />}>
            <AutobiographyProjectCheckoutContactStepContainer />
          </Suspense>
        ),
      },
      {
        path: RoutePath.CheckoutAutobiographyAddons,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BiographyProjectCheckoutAddonsStepContainer />
          </Suspense>
        ),
      },
      {
        path: RoutePath.CheckoutAutobiographyReview,
        element: (
          <Suspense fallback={<PageLoader />}>
            <AutobiographyProjectCheckoutReviewStepContainer />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: RoutePath.CheckoutBabyAudience,
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProjectCheckoutAudiencePage projectType="BABYBOOK" />
      </Suspense>
    ),
  },
  {
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProjectCheckoutContainer projectType="BABYBOOK" />
      </Suspense>
    ),
    children: [
      {
        path: RoutePath.CheckoutBabyHost,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BabybookProjectCheckoutContactStepContainer />
          </Suspense>
        ),
      },
      {
        path: RoutePath.CheckoutBabyProject,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BabybookProjectCheckoutBookStepContainer />
          </Suspense>
        ),
      },
      {
        path: RoutePath.CheckoutBabyReview,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BabybookProjectCheckoutReviewStepContainer />
          </Suspense>
        ),
      },
    ],
  },
  {
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProjectCheckoutContainer projectType="FREE" />
      </Suspense>
    ),
    children: [
      {
        path: RoutePath.CheckoutFreeDetails,
        element: (
          <Suspense fallback={<PageLoader />}>
            <FreeProjectCheckoutDetailsStepContainer />
          </Suspense>
        ),
      },
      {
        path: RoutePath.CheckoutFreeReview,
        element: (
          <Suspense fallback={<PageLoader />}>
            <FreeProjectCheckoutReviewStepContainer />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: RoutePath.CheckoutBundle,
    element: (
      <Suspense fallback={<PageLoader />}>
        <CheckoutBundlePage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.CheckoutGift,
    element: (
      <Suspense fallback={<PageLoader />}>
        <CheckoutGiftPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.CheckoutSuccess,
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProjectCheckoutSuccessPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.CheckoutSurvey,
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProjectCheckoutSurveyPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.CheckoutGetStarted,
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProjectCheckoutGetStartedPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.NewProject,
    element: (
      <Suspense fallback={<PageLoader />}>
        <NewProjectPage />
      </Suspense>
    ),
  },
  // Onboarding
  {
    path: RoutePath.Onboarding,
    element: <RementoPage type="default" />,
  },
  {
    path: RoutePath.OnboardingSignin,
    element: (
      <Suspense fallback={<PageLoader />}>
        <OnboardingSigninPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.OnboardingIntro,
    element: (
      <Suspense fallback={<PageLoader />}>
        <OnboardingIntroPage />
      </Suspense>
    ),
  },
  {
    element: <OnboardingContainer />,
    children: [
      {
        path: RoutePath.SetupPromptTypeSelection,
        element: (
          <Suspense fallback={<PageLoader />}>
            <SetupPromptTypeSelectionPage />
          </Suspense>
        ),
      },
      {
        path: RoutePath.SetupTextPromptSelection,
        element: (
          <Suspense fallback={<PageLoader />}>
            <SetupTextPromptSelectionPage />
          </Suspense>
        ),
      },
      {
        path: RoutePath.SetupPromptsReview,
        element: (
          <Suspense fallback={<PageLoader />}>
            <SetupPromptsReviewPage />
          </Suspense>
        ),
      },
      {
        path: RoutePath.SetupPromptsFrequency,
        element: (
          <Suspense fallback={<PageLoader />}>
            <SetupPromptsFrequencyPage />
          </Suspense>
        ),
      },
      {
        path: RoutePath.SetupPerspective,
        element: (
          <Suspense fallback={<PageLoader />}>
            <SetupPerspectivePage />
          </Suspense>
        ),
      },
      {
        path: RoutePath.SetupCollaborators,
        element: (
          <Suspense fallback={<PageLoader />}>
            <SetupCollaboratorsPage />
          </Suspense>
        ),
      },
    ],
  },
  // Invite
  {
    path: RoutePath.Invite,
    element: (
      <Suspense fallback={<PageLoader />}>
        <InvitePage />
      </Suspense>
    ),
  },
  // Book ordering
  {
    element: (
      <Suspense fallback={<PageLoader layout="navbar" />}>
        <BookOrderLayoutContainer />
      </Suspense>
    ),
    children: [
      {
        path: RoutePath.BookOrderCoverDesign,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BookOrderCoverDesignPage />
          </Suspense>
        ),
      },
      {
        path: RoutePath.BookOrderStories,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BookOrderStoriesPage />
          </Suspense>
        ),
      },
      {
        path: RoutePath.BookOrderPreview,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BookOrderPreviewPage />
          </Suspense>
        ),
      },
      {
        path: RoutePath.BookOrderQuantity,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BookOrderQuantityPage />
          </Suspense>
        ),
      },
      {
        path: RoutePath.BookOrderFinalize,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BookOrderFinalizePage />
          </Suspense>
        ),
      },
      {
        path: RoutePath.BookOrderComplete,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BookOrderCompletePage />
          </Suspense>
        ),
      },
    ],
  },
  // Book reordering
  {
    element: (
      <Suspense fallback={<PageLoader />}>
        <BookReorderLayoutContainer />
      </Suspense>
    ),
    children: [
      {
        path: RoutePath.BookReorderPreview,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BookReorderPreviewPage />
          </Suspense>
        ),
      },
      {
        path: RoutePath.BookReorderQuantity,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BookReorderQuantityPage />
          </Suspense>
        ),
      },
      {
        path: RoutePath.BookReorderFinalize,
        element: (
          <Suspense fallback={<PageLoader />}>
            <BookReorderFinalizePage />
          </Suspense>
        ),
      },
    ],
  },
  // Poll
  {
    path: RoutePath.Poll,
    element: (
      <Suspense fallback={<PageLoader />}>
        <PollPage />
      </Suspense>
    ),
  },
  // Questionnaire
  {
    path: RoutePath.QuestionnaireRoot,
    element: (
      <Suspense fallback={<PageLoader />}>
        <QuestionnairePage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.Questionnaire,
    element: (
      <Suspense fallback={<PageLoader />}>
        <QuestionnairePage />
      </Suspense>
    ),
  },
  // Other pages
  {
    path: RoutePath.Welcome,
    element: (
      <Suspense fallback={<PageLoader />}>
        <WelcomePage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.InApp,
    element: (
      <Suspense fallback={<PageLoader />}>
        <InAppPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.Signin,
    element: (
      <Suspense fallback={<PageLoader />}>
        <SigninPage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.InviteSignin,
    element: (
      <Suspense fallback={<PageLoader />}>
        <SigninPage isInvite={true} />
      </Suspense>
    ),
  },
  {
    path: RoutePath.Signout,
    element: <RementoPage type="default" />,
  },
  {
    path: RoutePath.AccountName,
    element: (
      <Suspense fallback={<PageLoader />}>
        <AccountNamePage />
      </Suspense>
    ),
  },
  {
    path: RoutePath.DebugDeleteAccount,
    element: (
      <Suspense fallback={<PageLoader />}>
        <DebugDeleteAccountPage />
      </Suspense>
    ),
  },

  // PDF
  {
    path: RoutePath.BookCover,
    element: (
      <Suspense fallback={<PageLoader />}>
        <BookCover />
      </Suspense>
    ),
  },
  {
    path: RoutePath.BookGutsCover,
    element: (
      <Suspense fallback={<PageLoader />}>
        <BookGutsCover />
      </Suspense>
    ),
  },
  {
    path: RoutePath.BookTableOfContents,
    element: (
      <Suspense fallback={<PageLoader />}>
        <BookTableOfContents />
      </Suspense>
    ),
  },
  {
    path: RoutePath.BookStoryContent,
    element: (
      <Suspense fallback={<PageLoader />}>
        <BookStoryContent />
      </Suspense>
    ),
  },
  {
    path: RoutePath.GiftCard,
    element: (
      <Suspense fallback={<PageLoader />}>
        <GiftCard />
      </Suspense>
    ),
  },
  // Token redirect
  {
    path: RoutePath.TokenRedirect,
    element: <TokenRedirectPage />,
  },
  // Broken Email Recovery
  {
    path: RoutePath.BrokenEmailRecovery_Question,
    element: (
      <NavigateWithParamsCallback
        to={(searchParams, params) => `${getProjectPromptsPath(params.projectId ?? '')}?${searchParams.toString()}`}
      />
    ),
  },
  // Legacy
  {
    path: RoutePath.LegacyCheckoutAudience,
    element: <NavigateWithParams to={getCheckoutAudiencePath('biography')} />,
  },
  {
    path: RoutePath.LegacySeriesSettings,
    element: <LegacyRedirect to={(params) => getProjectSettingsPath(params.projectId)} findProjectBy="personId" />,
  },
  {
    path: RoutePath.LegacySeriesSettingsRedirect,
    element: <NavigateWithParams to={RoutePath.ProjectSettingsLegacyRedirect} />,
  },
  {
    path: RoutePath.LegacyStoryStandalone,
    element: <LegacyRedirect to={(params) => getStandaloneStoryViewPath(params.storyId)} />,
  },
  {
    path: RoutePath.LegacyStory,
    element: (
      <LegacyRedirect to={(params) => getStoryViewPath(params.projectId, params.storyId)} findProjectBy="storyId" />
    ),
  },
  {
    path: RoutePath.LegacyQuestions,
    element: <LegacyRedirect to={(params) => getProjectPromptsPath(params.projectId)} findProjectBy="personId" />,
  },
  {
    path: RoutePath.LegacyRecordingIntro,
    element: <LegacyRedirect to={(params) => getRecordingIntroPath(params.projectId, params.promptId)} />,
  },
  {
    path: RoutePath.LegacyRecordingTypeSelection,
    element: (
      <LegacyRedirect
        to={(params) => getRecordingTypeSelectionPath(params.projectId, params.promptId, 'prompt-review')}
      />
    ),
  },
  {
    path: RoutePath.LegacyRecording,
    element: (
      <LegacyRedirect
        to={(params, searchParams) =>
          getRecordingPath({
            projectId: params.projectId,
            promptId: params.promptId,
            type: searchParams.get('type') as 'audio' | 'video',
            customParams: searchParams,
          })
        }
      />
    ),
  },
  {
    path: RoutePath.LegacyRecordingPromptRecorded,
    element: <LegacyRedirect to={(params) => getRecordingPromptRecordedPath(params.projectId, params.promptId)} />,
  },
  {
    path: RoutePath.LegacyAccountSettings,
    element: <LegacyRedirect to={() => getAccountSettingsPath()} />,
  },
];

export const routes: RouteObject[] = [
  {
    element: <RootRoute />,
    children: subRoutes,
    errorElement: <AppRouterErrorBoundary />,
  },
];
